import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import Banner from "../components/banner"
// import DashboardTable from "../components/part/dashboardTable"
import {
  TYPE_NEW_PARTICIPANT,
  TYPE_PARTICIPANT_LIST,
  TYPE_APPROVED,
} from "../constants/adminTableType"
import { LOGIN } from "../constants/routes"
import { Users, Files } from "../redux/api"
import { Table, Row, Col, Statistic } from "antd"
import { formatWithCurrency } from "../utils/currency"
import { API_ROOT } from "../redux/api"

const AdminPage = ({ state }) => {
  const [getType, setType] = useState(TYPE_NEW_PARTICIPANT)
  const [listUser, setListUser] = useState([])
  const [listFile, setListFile] = useState([])
  const [listStat, setListStat] = useState({})
  const [exportLink, setExportLink] = useState("")

  const handleTypeChange = event => {
    const value = event.target.value
    setType(value)
  }

  const handleActivate = async e => {
    const email = e.target.value
    await Users.activate({ email })
    await fetchData()
  }

  const TableColumnsNewParticipant = [
    { title: "id", dataIndex: "id" },
    { title: "Full Name", dataIndex: "fullname" },
    { title: "Email", dataIndex: "email" },
    { title: "Phone", dataIndex: "phone" },
    { title: "Country", dataIndex: "country" },
    {
      title: "Amount",
      dataIndex: "Invoices.total_price",
      render: (price, data) => (
        <div>{formatWithCurrency(price, data["Invoices.currency"])}</div>
      ),
    },
    {
      title: "Confirmed",
      dataIndex: "confirmed",
      render: confirm => <div>{confirm ? "✅ Yes" : "❌ No"}</div>,
    },
    {
      title: "Last updated",
      dataIndex: "updatedAt",
      render: d => <div>{new Date(d).toLocaleString()}</div>,
    },
    {
      title: "Action",
      render: (_, data) => (
        <button
          value={data.email}
          onClick={handleActivate}
          className="btn btn-primary"
        >
          Activated
        </button>
      ),
    },
  ]

  const TableColumnsApprovedParticipant = [
    { title: "id", dataIndex: "id" },
    { title: "Full Name", dataIndex: "fullname" },
    { title: "Email", dataIndex: "email" },
    { title: "Phone", dataIndex: "phone" },
    { title: "Country", dataIndex: "country" },
  ]

  const TableColumnsApprovedAbstract = [
    { title: "id", dataIndex: "id" },
    { title: "Title", dataIndex: "title" },
    { title: "Category", dataIndex: "category" },
    { title: "Keywords", dataIndex: "keywords" },
  ]

  const handleTableSelection = type => {
    if (type === TYPE_NEW_PARTICIPANT)
      return listUser.filter(u => u.activated === false)
    if (type === TYPE_PARTICIPANT_LIST)
      return listUser.filter(u => u.activated === true)
    if (type === TYPE_APPROVED) return listFile
  }

  const handleTableColumns = type => {
    if (type === TYPE_NEW_PARTICIPANT) return TableColumnsNewParticipant
    if (type === TYPE_PARTICIPANT_LIST) return TableColumnsApprovedParticipant
    if (type === TYPE_APPROVED) return TableColumnsApprovedAbstract
  }

  const fetchData = async () => {
    const rUser = await Users.details()
    setListUser(rUser ? (await rUser.json()).users : [])
    const rFile = await Files.details()
    setListFile(rFile ? (await rFile.json()).files : [])
    const rStats = await Users.stats()
    setListStat(rStats ? await rStats.json() : {})
  }

  useEffect(() => {
    const isLoggedIn = state.user.profile
    if (!isLoggedIn) return navigate(LOGIN)
    fetchData()
    const bpair = btoa(new Date().toDateString())
    setExportLink(`${API_ROOT}/users/download?bpair=${bpair}`)
  }, [state.user.profile])

  return (
    <Layout>
      <Banner title="Admin Dashboard"></Banner>
      <div className="container" style={{ padding: "4em 0" }}>
        <div className="col-12 form-group mb-4" style={{ padding: "0" }}>
          <Row gutter={16}>
            <Col span={4}>
              <Statistic title="Total Users" value={listStat.total} />
            </Col>
            <Col span={4}>
              <Statistic
                title="Waiting Payment"
                value={listStat.unpaid}
                suffix={"/ " + listStat.total}
              />
            </Col>
            <Col span={4}>
              <Statistic
                title="Waiting Verification"
                value={listStat.inactive}
                suffix={"/ " + listStat.total}
              />
            </Col>
            <Col span={4}>
              <Statistic
                title="Active Users"
                value={listStat.active}
                suffix={"/ " + listStat.total}
              />
            </Col>
            <Col span={4}>
              <Statistic title="Total Submission" value={listStat.files} />
            </Col>
          </Row>
          <br />
          <label className="mb-3">Pilih Tabel {getType}</label>
          <br />
          <div
            className="btn-group btn-group-toggle flex-wrap"
            data-toggle="buttons"
            style={{ width: "100%" }}
          >
            <button
              name="type"
              value={TYPE_NEW_PARTICIPANT}
              onClick={handleTypeChange}
              className={`btn btn-outline-secondary px-3 font-weight-semibold ls0 nott`}
            >
              Peserta baru
              <br />
              Cek status pembayaran peserta
            </button>
            <button
              name="type"
              value={TYPE_PARTICIPANT_LIST}
              onClick={handleTypeChange}
              className={`btn btn-outline-secondary px-3 font-weight-semibold ls0 nott`}
            >
              Data peserta
              <br />
              Daftar seluruh data peserta
            </button>
            <button
              name="type"
              value={TYPE_APPROVED}
              onClick={handleTypeChange}
              className={`btn btn-outline-secondary px-3 font-weight-semibold ls0 nott`}
            >
              Abstrak diterima
              <br />
              Daftar abstrak approved
            </button>
          </div>
        </div>
        <div className="col-12 mb-4 text-right">
          <a
            className={`btn btn-primary btn-lg${
              getType !== TYPE_APPROVED ? "" : " d-none"
            }`}
            href={exportLink}
            download={true}
          >
            Export Data
          </a>
        </div>
        <Table
          rowKey="id"
          dataSource={handleTableSelection(getType)}
          columns={handleTableColumns(getType)}
        ></Table>
      </div>
    </Layout>
  )
}

export default connect(state => ({ state }))(AdminPage)
