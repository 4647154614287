export const format = ({ price_idr, price_usd }) => {
  const dot = price_usd ? `,` : price_idr ? `.` : ``
  const sym = price_usd ? `$` : price_idr ? `Rp` : ``
  const val = price_usd || price_idr || ""
  return `${sym} ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, dot)}`
}

export const formatWithCurrency = (price, currency) => {
  if (!currency) return null
  if (currency === "IDR") return format({ price_idr: price })
  if (currency === "USD") return format({ price_usd: price })
  return null
}
